function handleModalClose(modal)
{
    const activeTab = window.location.hash || $('a[data-toggle="tab"]').first().attr('href');
    localStorage.setItem('selectedTab', activeTab);

    if (modal && modal.find('form').length) {
        modal.find('form')[0].reset();
    }

    location.reload();
}

// Make it globally accessible
window.handleModalClose = handleModalClose;
