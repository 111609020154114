function setUpAdvancedSearch()
{
    $('[data-toggle="tooltip"]').tooltip();
    $(".advanced_search_button").on("click", function() {
        advancedSearch();
    });

    $(".advanced_clear_button").on("click", function() {
        // Clear the textarea when resetting
        $('#advancedQuery').val('');
        clearAdvancedSearch();
    });
}

function advancedSearch()
{
    const zgRef = document.querySelector('zing-grid');

    // Trigger loading screen
    zgRef.setAttribute('loading', '');

    let originalSearchQuery = $("#advancedQuery").val();

    if(originalSearchQuery === '' || originalSearchQuery === undefined || originalSearchQuery === null) {
        return;
    }

    // Split each search term and put a comma. The format can be each term is on a new line or comma separated etc.
    originalSearchQuery = originalSearchQuery.split("\n").join(",").split("\r").join(",").split(",").filter(function(e){return e}).join(",");

    let searchQueryArray = originalSearchQuery.split(',');

    Array.from(searchQueryArray).forEach((searchTerm) => {
        searchTerm = searchTerm.trim()
    });

    let searchColumn = $("select[name=advanced_search_field]").val();

    $.ajax({
        method: 'POST',
        url: '/pim/product/inventory/advanced-search',
        data: {
            column: searchColumn,
            query: searchQueryArray
        }
    }).then(function(json) {
        // 
        setUpPimGrid(json, true);
    });

}

function clearAdvancedSearch()
{
    const zgRef = document.querySelector('zing-grid');
    // Trigger loading screen
    zgRef.setAttribute('loading', '');

    $.ajax({
        url: '/pim/product/inventory/get-products?pim_state=p_active'
    }).then(function(json) {
        setUpPimGrid(json);
    });
}