function prepareForm(el)
{
    const zgRef = document.querySelector('zing-grid');

    SC.PIM.Product.Bind.modal(el,function(){

        // Extract the selected Products from the local storage. This is needed in order to be able to do the assignment
        // for products that are on different pages.
        let productIds = localStorage.selectedProductIds;

        if (!productIds) {
            alert("Please select products.");
            return;
        }

        productIds = productIds.split(',');

        $('#modalForm .modal-content').html("");

        $('#modalForm').modal('show');

        let form = 'display-' + $(el).data('form') + '-form';

        $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {ids: productIds});

    });
}

function getProducts()
{
    //The assign_tag_form expects a method with this name, so it can redraw the DataTable.
    //Given that we are not using DataTables, we do not need to do anything
    return;
}