checked_pim_tags = [];
checked_pim_categories = [];
$(document).ready(function () {
    //initialize
    const all_btn = document.querySelector('input[name="status"][id="filter_active"]');
    if (all_btn) {
        all_btn.checked = true;
    }

    $('#list-categories').load(categoriesListURL);
    $('#list-tags').load(tagsListURL);
    $('#list-customer-tags').load(customertagsListURL);

    //select one tag
    $(document).on('click', '.tag', function () {
        if ($(this)[0].checked) {
            if (checked_pim_tags.indexOf($(this).val()) === -1) {
                checked_pim_tags.push($(this).val());
            }
        } else {
            checked_pim_tags = checked_pim_tags.filter(e => e !== $(this).val())
        }
    });

    //select one category
    $(document).on('click', '.category', function () {
        if ($(this)[0].checked) {
            if (checked_pim_categories.indexOf($(this).val()) === -1) {
                checked_pim_categories.push($(this).val());
            }
        } else {
            checked_pim_categories = checked_pim_categories.filter(e => e !== $(this).val())
        }
    });
});

function getStatusSelected() {
    const selectedStatus = document.querySelector('input[name="status"]:checked');

    if (!selectedStatus) {
        return;
    }

    return selectedStatus.value;
}

function getImageFilterSelected() {
    const selectedImageFilter = document.querySelector('#filterImage');

    if (!selectedImageFilter) {
        return;
    }
    return selectedImageFilter.value;
}

function SearchByCategoriesTags() {
    const selectedStatus = getStatusSelected();
    const selectedImageFilter = getImageFilterSelected();
    let selectedTags = checked_pim_tags || [];
    let selectedCategories = checked_pim_categories || [];
    const zgRef = document.querySelector('zing-grid');
    zgRef.refresh();
    document.getElementById('loader').style.display = 'flex';

    $.ajax({
        method: 'POST',
        url: '/pim/product/inventory/filter_by_tag_category',
        data: {
            checked_pim_tags: selectedTags,
            checked_pim_categories: selectedCategories,
            pim_state: selectedStatus,
            image_filter: selectedImageFilter
        }
    }).then(function (json) {
        document.getElementById('loader').style.display = 'none';
        let productData = json.data.products;

        // If the product does not have a main image set it to the default image
        Array.from(productData).forEach((product) => {
            if (!product.image) {
                product.image = '/src/img/image_unavailable.jpg';
            }
        });
        zgRef.data = productData;
        zgRef.refresh();
    });
}

function checkAll(id) {

    var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = true;
        if (id.includes("categories")) {
            if (checked_pim_categories.indexOf(checkboxes[i].value) === -1) {
                checked_pim_categories.push(checkboxes[i].value);
            }
        } else {
            if (checked_pim_tags.indexOf(checkboxes[i].value) === -1) {
                checked_pim_tags.push(checkboxes[i].value)
            }
        }
    }
}


// Function to uncheck all checkboxes with an identifer
function uncheckAll(id) {
    var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        if (id.includes("tag")) {
            checked_pim_tags = checked_pim_tags.filter(e => e !== checkboxes[i].value)
        }
    }
    if (id.includes("categories")) {
        checked_pim_categories = [];
    }
}

// Function to invert/toggle the current selection of checkboxes with an identifer
function toggleCheckboxes(id) {
    var checkboxes = document.querySelectorAll('#' + id + ' input[type="checkbox"]');
    for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = !checkboxes[i].checked;
        if (checkboxes[i].checked === true) {
            if (id.includes("categories")) {
                checked_pim_categories.push(checkboxes[i].value);
            } else {
                checked_pim_tags.push(checkboxes[i].value)
            }
        } else {
            if (id.includes("categories")) {
                checked_pim_categories = checked_pim_categories.filter(e => e !== checkboxes[i].value)
            } else {
                checked_pim_tags = checked_pim_tags.filter(e => e !== checkboxes[i].value)
            }

        }
    }
}