$(document).ready(function(){

    jQuery(function($) {

        // Variable to track the tree's disabled state
        let isDAMFolderTreeDisabled = false;

        jQuery('input[name=dam_all_assets]').change(function()
        {
            updateDamAssetsStatus();
        });

        function disableDamTags()
        {
            $('#select-deselect-ug-dam-tags').prop('disabled', true);
            $('.dam-panel-checklist input').prop('disabled', true);
        }

        function enableDamTags()
        {
            if(jQuery('#dam_all_assets__select_groups').is(':checked')){
                $('#select-deselect-ug-dam-tags').prop('disabled', false);
                $('.dam-panel-checklist input').prop('disabled', false);
            }
        }

        $('#select-deselect-ug-dam-tags').click(function() {
            var anyUnchecked = $('.ug-dam-tags input[type="checkbox"]:not(:checked)').length > 0;
            $('.ug-dam-tags input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });

        function updateDamAssetsStatus()
        {
            if (jQuery('#dam_all_assets__select_groups').is(':checked')) {
                enableDamTags();
                enableTree();
            } else {
                disableDamTags();
                disableTree();
            }
        }

        function disableTree()
        {
            var tree = $('#dam_folder_tree').jstree(true);

            if (tree) {
                var allNodeIds = tree.get_node('#').children_d;
                allNodeIds.forEach(function (nodeId) {
                    tree.disable_node(nodeId);
                });
            }

            $("#dam_folder_tree input").prop('disabled', true);
            $('#select-deselect-dam-folders').prop('disabled', true);

            isDAMFolderTreeDisabled = true;
        }

        function enableTree()
        {
            var tree = $('#dam_folder_tree').jstree(true);

            if (tree) {
                var allNodeIds = tree.get_node('#').children_d;
                allNodeIds.forEach(function (nodeId) {
                    tree.enable_node(nodeId);
                });
            }

            $("#dam_folder_tree input").prop('disabled', false);
            $('#select-deselect-dam-folders').prop('disabled', false);

            isDAMFolderTreeDisabled = false;
        }

        // Ensure the tree is updated when ready
        $('#dam_folder_tree').on('ready.jstree', function () {
            updateDamAssetsStatus();
        });

        // Initial setup
        updateDamAssetsStatus();

        $('.select-all-dam-tags').click(function() {
            var anyUnchecked = $('.dam-tags input[type="checkbox"]:not(:checked)').length > 0;
            $('.dam-tags input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });

        var is_folder_check_all = false;
        $('.select-all-folders').bind('click', function() {
            if(!is_folder_check_all){
                is_folder_check_all = true;
                $("#dam_folder_tree").jstree("check_all");
            }
            else{
                is_folder_check_all = false;
                $("#dam_folder_tree").jstree("uncheck_all");
            }
        });

        $(":submit").click(function() {

            selected_folders = new Array();
            var selected_el = $('#dam_folder_tree').jstree("get_selected", true);
            $.each(selected_el, function() {

                if ($.inArray(this.id, selected_folders) == -1 && this.id != '#') {
                    selected_folders.push(this.id);
                }
                if ((this.parents.length) > 0) {
                    for (var i = 0, len = this.parents.length; i < len; i++) {
                        if ($.inArray(this.parents[i], selected_folders) == -1 && this.parents[i] != '#') {
                            selected_folders.push(this.parents[i]);
                        }
                    }
                }
            });

            if (!isDAMFolderTreeDisabled) {
                if ((selected_folders.length) > 0) {
                    for (var i = 0, len = selected_folders.length; i < len; i++) {
                        $("form").append('<input type="hidden" name="folder_id[]" value="' + selected_folders[i] + '" />');
                    }
                }
            }

        });


    });
});