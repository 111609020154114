function storeSelection(zgRef, data)
{
    // 

    let rowIndex  = data.rowIndex;
    let pageIndex = zgRef.getPageIndex();
    let pageSize  = zgRef.getPageSize();

    if (rowIndex > (pageIndex * pageSize)) {
        // We do not want to store indexes from previous pages again
        return;
    }

    let storedRowIndexes = JSON.parse(localStorage.getItem("selectedRows"));
    // 

    if (!storedRowIndexes[pageIndex]) {
        storedRowIndexes[pageIndex] = [];
    }

    if (storedRowIndexes[pageIndex].includes(rowIndex)) {

        if (!data.checked) {
            let idx = storedRowIndexes[pageIndex].indexOf(rowIndex);

            storedRowIndexes[pageIndex].splice(idx, 1);

            localStorage.setItem("selectedRows", JSON.stringify(storedRowIndexes));
        }

        return;
    }

    storedRowIndexes[pageIndex].push(rowIndex);

    localStorage.setItem("selectedRows", JSON.stringify(storedRowIndexes));

}

function retrieveSelection(zgRef)
{
    let storedRowIndexes = JSON.parse(localStorage.getItem("selectedRows"));

    let pageIndex = zgRef.getPageIndex();
    let pageSize = zgRef.getPageSize();

    if (!storedRowIndexes[pageIndex]) {
        // No selections on this page
        return;
    }

    Array.from(storedRowIndexes[pageIndex]).forEach((rowIndex) => {

        if (pageIndex > 1) {
            // Rows are indexed from 0. On page 2,3,4 etc their selection indexes start from 0 too.
            // This is not to be confused with the actual rowIndex. Each row has its own unique index.
            // Example: The 3rd row on page 3 will have unique index row of 52 (this is given that the page size is 25).
            // But its selection index will be 2.
            rowIndex = (rowIndex - (pageSize * (pageIndex - 1)));
        }

        zgRef.selectRow(rowIndex);
    });
}

function storeSelectedProducts(productData)
{
    let productId = productData.id;

    let productIds = localStorage.selectedProductIds;
    // 

    if (productIds.includes(productId)) {

        productIds = productIds.split(',');

        let idx = productIds.indexOf(productId);

        productIds.splice(idx, 1);

        productIds = productIds.join(',');

        localStorage.selectedProductIds = productIds;

        return;
    }

    productIds = productIds !== '' ? productIds + ',' : productIds;

    productIds += productId;

    localStorage.selectedProductIds = productIds;

}