$(document).ready(function(){

    jQuery(function($) {

        jQuery('input[name=pim_all_products]').change(function()
        {
            updatePimProductsStatus();
        });

        function updatePimProductsStatus()
        {
            if(jQuery('#pim_all_products__select_groups').is(':checked'))
            {
                enablePimTagsAndCats();
            } else {
                disablePimTagsAndCats();
            }
        }

        function enablePimTagsAndCats()
        {
                $('#select-deselect-pim-tags').prop('disabled', false);
                $('#select-deselect-pim-cats').prop('disabled', false);
                $('.pim-panel-checklist input').prop('disabled', false);
        }

        function disablePimTagsAndCats()
        {
                $('#select-deselect-pim-tags').prop('disabled', true);
                $('#select-deselect-pim-cats').prop('disabled', true);
                $('.pim-panel-checklist input').prop('disabled', true);
        }

        $('.select-all-categories').click(function() {
            var anyUnchecked = $('.categories input[type="checkbox"]:not(:checked)').length > 0;
            $('.categories input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });

        $('.select-all-pim-tags').click(function() {
            var anyUnchecked = $('.pim-tags input[type="checkbox"]:not(:checked)').length > 0;
            $('.pim-tags input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });

        updatePimProductsStatus();

    });

});