$(document).ready(function(){

    jQuery(function($) {

        jQuery('input[name=pim_all_export_templates]').change(function()
        {
            updatePimExporTemplateStatus();
        });

        function updatePimExporTemplateStatus()
        {
            if(jQuery('#pim_all_export_templates__select_groups').is(':checked'))
            {
                enablePimExporTemplates();
            } else {
                disablePimExporTemplates();
            }
        }

        function enablePimExporTemplates()
        {
            $('#select-deselect-export-templates').prop('disabled', false);
            $('.ug-export-templates input').prop('disabled', false);
        }

        function disablePimExporTemplates()
        {
            $('#select-deselect-export-templates').prop('disabled', true);
            $('.ug-export-templates input').prop('disabled', true);
        }

        updatePimExporTemplateStatus();

        $('#select-deselect-export-templates').click(function() {
            var anyUnchecked = $('.ug-export-templates input[type="checkbox"]:not(:checked)').length > 0;
            $('.ug-export-templates input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });

    });

});