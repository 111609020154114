$(document).ready(function(){

    jQuery(function($) {

        jQuery('input[name=pim_all_attribute_groups]').change(function()
        {
            updatePimAttributeGroupStatus();
        });

        function updatePimAttributeGroupStatus()
        {
            if(jQuery('#pim_all_attribute_groups__select_groups').is(':checked'))
            {
                enablePimAttributeGroups();
            } else {
                disablePimAttributeGroups();
            }
        }

        function enablePimAttributeGroups()
        {
            $('#select-deselect-ug-attribute-groups').prop('disabled', false);
            $('.ug-attribute-groups input').prop('disabled', false);
        }

        function disablePimAttributeGroups()
        {
            $('#select-deselect-ug-attribute-groups').prop('disabled', true);
            $('.ug-attribute-groups input').prop('disabled', true);
        }

        updatePimAttributeGroupStatus();

        $('#select-deselect-ug-attribute-groups').click(function() {
            var anyUnchecked = $('.ug-attribute-groups input[type="checkbox"]:not(:checked)').length > 0;
            $('.ug-attribute-groups input[type="checkbox"]').prop('checked', anyUnchecked).change();
        });
    });

});