function loadPim()
{
    // Default the selectedRows and selectedProductIds every time the page is loaded
    localStorage.setItem("selectedRows", JSON.stringify([]));
    localStorage.setItem("selectedProductIds", '');

    const zgRef = document.querySelector('zing-grid');

    // Bind the selection and pagination events
    onSelection(zgRef);
    onPageChange(zgRef);

    $.ajax({
        url: '/pim/product/inventory/get-products?pim_state=p_active'
    }).then(function(json) {
        setUpPimGrid(json);
    });
}

function setUpPimGrid(json, isAdvancedSearch = false)
{
    let productData = json.data.products;
    let hasImage    = json.data.hasImage;

    if (!productData) {
        productData = [];
    }

    // If the product does not have a main image set it to the default image
    Array.from(productData).forEach((product) => {
        if (!product.image) {
            product.image = '/src/img/image_unavailable.jpg';
        }

        // Display the columns with object values as a json string on the grid
        for (const [key, value] of Object.entries(product)) {
            // 
            if (value && typeof value === 'object') {
                product[key] = JSON.stringify(value);
            }
        }
    });

    let productAttributes = json.data.attributes;
    let allColumns = setTableColumnsFromAttributes(productAttributes);

    if (!isAdvancedSearch) {
        getCounts(json.data);

        // Remove the loading text from the advanced search field and assign the attributes from the API
        $("select[name=advanced_search_field]").html("");
        setAdvancedSearchColumnsFromAttributes(productAttributes);
    }

    const zgRef = document.querySelector('zing-grid');

    zgRef
        .setFrozenColumnsLeft(3) // Set the first 3 columns to frozen left (checkbox, image, sku)
        .setColumns(allColumns)
        .setData(productData);

    zgRef.refreshGrid();
}

function setTableColumnsFromAttributes(attributes)
{
    let productColumns = [];

    //set image as second column (first column is the checkbox)
    productColumns.push({
        index          : "id, image",
        header         : "Main Image",
        type           : "image",
        sort           : "disabled",
        frozen         : "left",
        filter         : "disabled",
        drag           : "disabled",
        "column-width" : "fitheader",
        renderer       : "render_product_image",
        editor         : "false",
    });

    // set SKU as third column
    productColumns.push({
        index    : "sku, id",
        header   : "SKU",
        frozen   : "left",
        drag     : "disabled",
        editor   : "false",
        renderer : "render_sku",
        width    : "fit"
    });

    // set the rest of the attributes as columns
    Array.from(attributes).forEach((attribute) => {

        let column = {
            index  : attribute.name,
            header : attribute.label,
            type   : "text",
            width  : "fit"
        };

        if (attribute.type == 4) { // TextArea/HTML column
            column.renderer      = "render_accordion";
            column.filter        = "disabled";
            column.sort          = "disabled";
            column["cell-class"] = "accordion-flex-layout"
        }

        productColumns.push(column);

    });

    return productColumns;
}

// Custom renderer for the Product Image
window.render_product_image = (currentId, currentImage, DOMCell, $cell) => {
    return '<a href="/pim/product/inventory/get/' + currentId + '" target="_blank"> ' +
    '   <img class="lazy loading"' +
    '       src="/TimThumb.php?src=' + currentImage + '&amp;w=50&amp;h=50&amp;zc=2"' +
    '       width="50"' +
    '       height="50"' +
    '       style=""> ' +
    '</a>';
}

// Custom renderer for the Product Image
window.render_sku = (currentSku, currentId, DOMCell, $cell) => {
    return '<a href="/pim/product/inventory/get/' + currentId + '" target="_blank"> ' + currentSku + '</a>';
}


// global event handler for expanding dropdowns
expandHandler = function() {
    this.classList.toggle('active');
    this.nextElementSibling.classList.toggle('show');
}

// generate randomId for dropdowns
randomId = () => {
    return Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 10);
}

// custom render function for accordion (textarea/html)
window.render_accordion = (textArea, DOMCell, $cell) => {
    if (textArea) {
        const dropdownID = randomId();
        var id = `accordion_${dropdownID}`;

        var label = textArea.replace(/(<([^>]+)>)/ig, '');

        label = label.slice(0, 25);

        return '<div>' +
                '<section class="accordion">' +
                    '<input type="checkbox" id="'+ id+ '" >' +
                    '<label for="'+ id+ '">' + label + '...</label>' +
                    '<article>' +
                        '<p class="textArea">' + textArea + '</p>' +
                    '</article>' +
                '</section>' +
            '</div>';
    }

    return '<div>-</div>';
}

function setAdvancedSearchColumnsFromAttributes(attributes)
{
    // Add SKU as first element
    var o = new Option("SKU", 'sku');
    $(o).html("SKU");
    $("select[name=advanced_search_field]").append(o);


    Array.from(attributes).forEach((attribute) => {
        assignAttributesToAdvancedSearchSelect(attribute);
    });
}


function assignAttributesToAdvancedSearchSelect(attribute)
{
    var o = new Option(attribute.label, attribute.name);
    $(o).html(attribute.label);
    $("select[name=advanced_search_field]").append(o);
}


/**
 * The following methods add an event listener to certain actions, so we can add some custom functionality.
 */
function onPageChange(zgRef)
{
    zgRef.addEventListener('grid:pagechange', (e) => {
        setTimeout(() => retrieveSelection(zgRef), 100);
    });
}

function onSelection(zgRef)
{
    zgRef.addEventListener('row:select', (e) => {
        storeSelection(zgRef, e.detail.ZGData);
        storeSelectedProducts(e.detail.ZGData.data)
    });
}

function numberWithCommas(x)
{
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getCounts(data)
{
    $('#total_active').html(numberWithCommas(data.total_active));
    $('#total_inactive').html(numberWithCommas(data.total_inactive));
    $('#total_count').html(numberWithCommas(data.total_count))
}

function captionText()
{
    const zgRef = document.querySelector('zing-grid');

    let status = getStatusSelected();
    let text = '';
    switch(status) {
        case 'p_active':
            text = 'Active Products'
            break;
        case 'p_inactive':
            text = 'Inactive Products'
            break;
        default:
            text = 'All Products'
    }

    zgRef.setCaption(text);
}