<!-- PDF & Data Templte Exports -->
function prepareExportForm(el) {
    SC.PIM.Product.Bind.modal(el, function() {

        // Get selected product IDs from localStorage
        let pimProductIdString = localStorage.selectedProductIds || ''; // Default to empty string if null
        let productStringLength = pimProductIdString.length;

        if (productStringLength === 0) {
            alert("Please select products.");
            return;
        }

        // Split the IDs and get the count
        let pimProductIds = pimProductIdString.split(',').filter(el => el.trim() !== ''); // Filter out any empty values
        let productCount = pimProductIds.length;

        if (productCount > 0 && productCount <= maximumUIExports) {

            let form = 'display-' + $(el).data('form') + '-form';
            let type = $(el).data('type');

            showModal()
            loadExportImageModal(form, type, pimProductIds);

        } else if (productCount === 0) {
            alert("Please select products.");
        } else {
            alert("This feature only supports up to " + maximumUIExports + " records at this time (You selected " + productCount + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datasets.");
        }
    });
}

<!-- PDF & Data Templte Exports -->
$(function() {
    $(document).on('click', '.export_as', function () {

        // Get selected product IDs from localStorage
        let pimProductIdString = localStorage.selectedProductIds || '';
        let productStringLength = pimProductIdString.length;

        if (productStringLength === 0) {
            alert("Please select products.");
            return;
        }

        // Split the IDs and get the count
        let pimProductIds = pimProductIdString.split(',').filter(el => el.trim() !== '');
        let productCount = pimProductIds.length;
        let form = 'display-' + $(this).data('form') + '-form';
        let type = $(this).data('type');
        let template = $(this).data('template');
        let name = $(this).data('name');

        if ((productCount <= maxPostFields && type == 'pdf') || (productCount <= maximumUIExports)) {
            showModal();
            loadExportDataPdfModal(form, type, pimProductIds, template, name)
        } else {
            alert("This feature only supports up to " + maximumUIExports + " records at this time (You selected " + productCount + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datasets.");
        }

    });
});

function showModal()
{
    $('#modalForm .modal-content').html("");
    $('#modalForm').modal('show');
}

function loadExportImageModal(form, type, pimProductIds)
{
    $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {
        ids: pimProductIds,
        type: type
    });
}

function loadExportDataPdfModal(form, type, pimProductIds, template, name)
{
    $('#modalForm .modal-content').load('/pim/product/inventory/' + form, {
        ids: pimProductIds,
        template: template,
        name: name,
        type: type
    });
}