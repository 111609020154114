$(document).ready(function() {
    $(function () {
        $("#resync_images").click(function (event) {
            const zgRef = document.querySelector('zing-grid');
            let selected = zgRef.getSelectedRows();
            // Extract ids from selected rows
            let ids = $.map(selected, function (item) {
                return item.id;
            });
            if (ids.length > 0 && ids.length <= 1000) {
                $.ajax({
                    method: "POST",
                    url: '/pim/product/inventory/resync-images',
                    data: {product_id: ids}
                }).done(function (msg) {
                    showNoty("success", "Sync Completed, reloading data");
                    getProducts();
                });
            } else {
                alert("Please select products.");
            }
        });
    });
});