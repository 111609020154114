function downloadAsset(type)
{
    let productIds = localStorage.selectedProductIds;

    if (!productIds) {
        alert("Please select products.");
        return;
    }

    let ids = productIds.split(",");

    var count = ids.length;

    // 1000 is the max
    if ( count <= 1000 ) {
        data = {
            download_type: type,
            "product_id[]": ids
        }

        formSubmit('/pim/product/download/products-as-csv', data);

    } else {
        alert("This feature only supports up to 1000 records at this time (You selected " + count + "). Please use Tags and Categories, along with the 'Export As' option from the main navigation for larger datesets.")
    }
}

function formSubmit(path, params, method='post')
{
    // modified from https://stackoverflow.com/questions/133925/javascript-post-request-like-a-form-submit
    // a few recursive models that can be swapped out if required

    const form = document.createElement('form');
    form.method = method;
    form.action = path;

    for (const key in params) {
        if (params.hasOwnProperty(key)) {
            const hiddenField = document.createElement('input');
            hiddenField.type = 'hidden';
            hiddenField.name = key;

            if(Array.isArray(params[key]))
            {
                params[key].forEach(function(item, index) {
                    hiddenField.value = item;
                    form.appendChild(hiddenField.cloneNode(true));
                });
            } else {
                hiddenField.value = params[key];
                form.appendChild(hiddenField);
            }
        }
    }

    document.body.appendChild(form);
    form.submit();
}