$(document).ready(function(){

    jQuery(function($) {

        $('#user-group-delete').on('shown.bs.modal', function (event) {
            var button = $(event.relatedTarget); // Button that triggered the modal
            var id = button.data('id');
            var name = button.data('name');

            var modal = $(this);
            modal.find('.modal-title').text("Delete User Group");

            modal.find('.modal-id').val(id);
            modal.find('.modal-name').val(name);
            modal.find('span').text("'" + name + "'");

        });

    });

});